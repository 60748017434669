module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-55244701-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"brands and emotions","short_name":"brands and emotions","start_url":"/","lang":"de","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/svg/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33112d8bdb0c64204168d3e0a95d2553"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/techmynder/clients/brandsandemotions/brandsandemotions.com/src/data/i18n","languages":["de","en"],"defaultLanguage":"de","redirect":true,"redirectDefaultLanguageToRoot":false,"redirectComponent":"/builds/techmynder/clients/brandsandemotions/brandsandemotions.com/src/components/redirect.tsx","ignoredPaths":[],"fallbackLanguage":"de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ea690b","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/techmynder/clients/brandsandemotions/brandsandemotions.com/src/cms.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
