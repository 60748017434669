exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-benefits-boost-your-startup-tsx": () => import("./../../../src/pages/benefits/boost-your-startup.tsx" /* webpackChunkName: "component---src-pages-benefits-boost-your-startup-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../../../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovations-tsx": () => import("./../../../src/pages/innovations.tsx" /* webpackChunkName: "component---src-pages-innovations-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

